import React from "react";

import { Amplify } from "aws-amplify";
import { Authenticator, useTheme, ThemeProvider, } from "@aws-amplify/ui-react";
import awsconfig from "../aws-exports";
import "@aws-amplify/ui-react/styles.css";

import Main from "./Main";

import theDailyCompLogo from "../assets/Main_logo_proto1.svg";

Amplify.configure(awsconfig);

const Login = () => {
    const components = {
        Header() {
            const { tokens } = useTheme();
            return (
                <div style={{ textAlign: "center", padding: tokens.space.large }}>
                    <img
                        alt="The daily comp logo"
                        src={theDailyCompLogo}
                    />
                </div>
            );
        },

        Footer() {
            return (
                <div style={{ textAlign: "center", fontSize: "12.5px" }}>
                    <p>Challenge your friends, co-workers or family to a variety of daily word based challenges to see who can take out the golden pencil and claim the title of word connoisseur. All ready have an account? Sign in now, or else create a free account now to join the daily competitions.</p>
                </div>
            );
        }
    }

    const getInitialState = () => {
        if (window.location.href.includes("/login")) return undefined;

        return "signUp"
    }

    return (
        <div style={{ width: "100%", height: "100vh", backgroundColor: "lightGrey" }}>
            <ThemeProvider >
                <Authenticator components={components} initialState={getInitialState()} >
                    {({ signOut, user }) => (
                        <Main
                            signOut={signOut}
                            user={user}
                        ></Main>
                    )}
                </Authenticator >
            </ThemeProvider>
        </div>
    )
}

export default Login;