import React from "react";
import { Modal, Button } from "react-bootstrap";

import { URL_HELPER } from "../utils/constants";

import { archiveCompetition } from "../https_handler/apiHandler";

import "bootstrap/dist/css/bootstrap.min.css";

const ArchiveCompModal = ({ show, handleClose, user, activeCompetition, showErrorModal }) => {
    const [isLoading, setLoading] = React.useState(false);

    const hideModal = () => {
        if (!isLoading)
            handleClose();
    }

    const setUrlToBase = () => {
        window.location.href = URL_HELPER.URL_BASE_STRING;
    }

    const handleArchiveCompetition = async () => {
        if (!isLoading) {
            setLoading(true);
            const { error } = await archiveCompetition(user, activeCompetition.compInfo.compId);

            setLoading(false);

            if (error)
                showErrorModal(error);
            else
                setUrlToBase();
        }
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <p className="model-heading" > Deleting Competition {activeCompetition ? activeCompetition.compInfo.title : null}</p>
            </Modal.Header>
            <Modal.Body>
                <>
                    <p>Are you sure you want to delete competition: {activeCompetition ? activeCompetition.compInfo.title : null}</p>
                </>
            </Modal.Body>
            <Modal.Footer className="model-footer" >
                <Button variant="secondary" onClick={hideModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleArchiveCompetition}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ArchiveCompModal;