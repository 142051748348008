import React from "react";
import "./CompInfo.css";

import CountDownTimer from "./CountDownTimer";
import { DateTime } from "luxon";

const CompInfo = ({ activeCompetition, reloadNewComp, remainingGuesses, guesses }) => {
    const [word, setWord] = React.useState("");

    const shouldRenderWord = () => {
        setWordToRender();

        if (guesses && guesses.length > 0 && guesses[guesses.length - 1].result.word) return true;
        if (activeCompetition && activeCompetition.user && activeCompetition.user.currentGuesses.length > 0 && activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].word) return true;

        return false;
    }

    const setWordToRender = () => {
        if (guesses && guesses.length > 0 && guesses[guesses.length - 1].result.word) {
            if (word === guesses[guesses.length - 1].result.word) return;


            setTimeout(() => {
                setWord(guesses[guesses.length - 1].result.word)
            }, 5000);
            return;
        }

        if (activeCompetition && activeCompetition.user && activeCompetition.user.currentGuesses.length > 0 && activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].word) {
            if (word === activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].word) return;

            setWord(activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].word);
            return;
        }

        if (word)
            setWord();
        return false;
    }

    return (
        <div className="game-info-container">
            <div className="game-info-container-left">
                <p className="title">Competition: {activeCompetition.compInfo.title}</p>
                <div className="game-info-container">
                    <p className="text-comp-info">Owner: {activeCompetition.compInfo.createdBy} {activeCompetition.compInfo.isUserOwner ? "(you)" : ""}</p>
                    <p className="text-comp-info">Word length: {activeCompetition.compInfo.wordLength}</p>
                    <p className="text-comp-info">Attempts: {remainingGuesses}</p>
                </div>
                {shouldRenderWord() ? <p className="text-comp-info">Correct word: {word}</p> : undefined}
            </div>

            <div className="game-info-container-right">

                <div className="game-status-container">
                    <div
                        className="status-circle"
                        style={{ backgroundColor: getCompetitionStatusColour(activeCompetition) }}
                    ></div>
                    <p className="game-status-text">{getCompetitionStatusText(activeCompetition)}</p>
                </div>
                {activeCompetition.compInfo.started ? <p className="text">{activeCompetition.compInfo.finished ? "Finished on" : "Until"}: {DateTime.fromJSDate(new Date(activeCompetition.compInfo.endDate)).toLocaleString(DateTime.DATE_FULL)}</p> : null}

                {!activeCompetition.compInfo.started ? <> <p className="text"></p> <CountDownTimer startDate={activeCompetition.compInfo.startDate} reloadNewComp={reloadNewComp}></CountDownTimer> </> : null}

            </div>
        </div>
    )
}

const getCompetitionStatusColour = (competition) => {
    if (competition.compInfo.finished) return "red";

    if (!competition.compInfo.started) return "yellow";

    return "green";
}

const getCompetitionStatusText = (competition) => {
    if (competition.compInfo.finished) return "Finished";

    if (!competition.compInfo.started) return "Starting soon";

    return "Running";
}



export default CompInfo;