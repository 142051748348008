import React from "react";
import InlineSpinner from "./InlineSpinner";


import { useCountdown } from "./useCountDown";

const CountDownTimer = ({ startDate, text, reloadNewComp, showTimerLoading }) => {
    const [days, hours, minutes, seconds] = useCountdown({ startDate, reloadNewComp });

    return (
        <>
            {
                showTimerLoading ? <p className="text">{text}<InlineSpinner></InlineSpinner> </p> :
                    <p className="text">
                        {text} {days > 0 ? `${days} d` : null}  {days + hours > 0 ? `${hours} h` : null} {minutes} m {seconds}s
                    </p>
            }
        </>

    )
}





export default CountDownTimer;