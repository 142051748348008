export const ALPHABET = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

export const KEYBOARD_INPUT_KEYS = {
    BACKSPACE: "backspace",
    SCREEN_KEYBOARD_BACKSPACE: "{bksp}",
    SCREEN_KEYBOARD_ENTER: "{enter}",
    ENTER: "enter",
    TAB: "tab",
}

export const URL_HELPER = {
    URL_BASE_STRING: "https://thedailycomp.com/",
    URL_COMPETITION_PARAM: "competitions/",
}

export const ROLL_OVER_STATUSES = {
    succeeded: "SUCCEEDED",
    running: "RUNNING",
}

export const COMPETITION_SUPPORTED_WORD_LENGTHS = ["3", "4", "5", "6", "7"];

export const GENERIC_ERROR = { error: "Unknown response from server please try again later" };
export const STATUS_202 = 202;
export const STATUS_200 = 200;
export const STATUS_400 = 400;
export const BASE_URL = "https://api.thedailycomp.com/api";
export const HTTP_METHOD_GET = "GET";
export const HTTP_METHOD_DELETE = "DELETE";
export const HTTP_METHOD_POST = "POST";

export const ON_SCREEN_KEYBOARD_LAYOUT = {
    default: [
        "q w e r t y u i o p {bksp}",
        "a s d f g h j k l {enter}",
        "z x c v b n m",
    ],
}

export const CREATE_COMP_STATES = {
    STATE_TITLE: "STATE_TITLE",
    STATE_GUESSES: "STATE_GUESSES",
    STATE_WORD_LENGTH: "STATE_WORD_LENGTH",
    STATE_TYPE: "STATE_TYPE",
    STATE_DIFFICULTY: "STATE_DIFFICULTY",
    STATE_DATE_RANGE: "STATE_DATE_RANGE",
}

export const COMPETITION_THEME_POKEMON = "theme_pokemon";
export const COMPETITION_THEME_COUNTRIES = "theme_countries";
export const COMPETITION_THEME_SCRABBLE = "theme_scrabble";

export const CREATE_COMP_STATES_ARRAY = [
    "STATE_TITLE",
    "STATE_GUESSES",
    "STATE_TYPE",
    "STATE_WORD_LENGTH",
    "STATE_DIFFICULTY",
    "STATE_DATE_RANGE",
]