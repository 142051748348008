import "./Main.css"
import React, { useEffect, useCallback } from "react";
import { GoSignOut, GoQuestion } from "react-icons/go";

import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import CreateCompModel from "./CreateCompModel";
import ArchiveCompModal from "./ArchiveCompModal";
import Game from "./Game";
import Results from "./Results";
import ErrorModal from "./errorModal";
import CompInfo from "./CompInfo";

import { getCompetitionsForUser, getCompetitionForUser, getStatusOfRollOver } from "../https_handler/apiHandler";
import Scoreboard from "./Scoreboard";
import CompDropdown from "./CompDropdown";
import InviteUserModal from "./InviteUserModal";

import { URL_HELPER, ROLL_OVER_STATUSES } from "../utils/constants";

import theDailyCompLogo from "../assets/Main_logo_proto1.svg";

import Joyride from "react-joyride";

const Main = ({ user, signOut }) => {
    const [guesses, setGuesses] = React.useState([]);
    const [competitions, setCompetitions] = React.useState([]);
    const [archivedCompetitions, setArchivedCompetitions] = React.useState([]);
    const [activeCompetition, setActiveCompetition] = React.useState(null);
    const [activeCompetitionLoading, setActiveCompetitionLoading] = React.useState(true);
    const [isCreateModelOpen, setIsCreateModelOpen] = React.useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = React.useState();
    const [errorModelMessage, setErrorModelMessage] = React.useState("The error message");
    const [canUserInviteComp, setCanUserInviteComp] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [dateToNextWord, setDateToNextWord] = React.useState();
    const [showScoreBoard, setShowScoreBoard] = React.useState(true);
    const [remainingGuesses, setRemainingGuesses] = React.useState();

    const [showTimerLoading, setShowTimerLoading] = React.useState(false);


    const [joyrideShouldRun, setJoyrideShouldRun] = React.useState(false);
    const [joyRideSteps] = React.useState([
        {
            target: ".view-competitions-button",
            content: "View all your competitions here, both those you create and those you are invited to can be found here!",
        },
        {
            target: ".create-competitions-button",
            content: "Create your own competition here and challenge your friends or co-workers!",
        },
        {
            target: ".sign-out-button",
            content: "All good things must come to an end... so once your done for the day sign out here.",
        },
        {
            target: ".game-play-container",
            content: "Everything for playing the game will be in here, information about your competition and the ability to make a guess will show up here.",
        },
        {
            target: ".letter-input-container",
            content: "Make an educated guess against a selected competition here. Once focused type with your keyboard or alteratively click me to view the on screen keyboard (Mobile devices only)!",
        },
        {
            target: ".show-onscreen-keyboard-button",
            content: "If you don't want to use your keyboard prompt the onscreen keyboard here",
        },
        {
            target: ".guess-button",
            content: "If you don't want to use a keyboard send your guess away here!",
        },
        {
            target: ".game-scoreboard-container",
            content: "View your score here and how you compare against your peers every month!",
        },
    ]);


    const handleClose = () => setIsCreateModelOpen(false);
    const handleShow = () => setIsCreateModelOpen(true);

    const handleInviteModalClose = () => setIsInviteModalOpen(false);
    const handleInviteModalShow = () => setIsInviteModalOpen(true);

    const handleDeleteModalClose = () => setIsDeleteModalOpen(false);


    const signUserOut = useCallback(() => {
        signOut();
    }, [signOut])

    const handleErrorModalClose = () => setIsErrorModalOpen(false);

    const handleErrorModalShow = (message) => {
        setErrorModelMessage(message);
        setIsErrorModalOpen("aValue");
    }

    const getActiveAndArchivedComps = useCallback((competitions) => {
        const archivedComps = [];
        const activeComps = [];

        for (const comp of competitions) {
            const { finished } = comp;
            if (finished) {
                archivedComps.push(comp)
                continue;
            }

            activeComps.push(comp);
        }

        return { archivedComps, activeComps };
    }, [])

    const isCompOneMonthOld = (endDate) => {
        const dateObj = new Date(endDate);
        const currentDate = new Date(Date.now());
        currentDate.setMonth(currentDate.getMonth() - 1);

        if (dateObj < currentDate)
            return true;

        return false;
    }

    const handleArchiveComp = async () => {
        setIsDeleteModalOpen(true);
    }

    const setUrlToBase = () => {
        window.location.href = URL_HELPER.URL_BASE_STRING;
    }

    const reloadCompetitionScoreboard = async () => {
        setActiveCompetition({ ...activeCompetition, topUsers: [] });
        await getCompInfo(activeCompetition.compInfo.compId);
    }

    const getCompInfo = useCallback(async (compId) => {
        setActiveCompetitionLoading(true);
        const result = await getCompetitionForUser(user, compId);

        const { error } = result;
        if (error) {
            setActiveCompetitionLoading(false);
            handleErrorModalShow(error);
            setTimeout(setUrlToBase, 1500);
            return;
        }

        setRemainingGuesses(result.compInfo.maxGuesses - result.user.currentGuesses.length)
        setActiveCompetition(result);
        setCanUserInviteComp(result.compInfo.isUserOwner);
        setActiveCompetitionLoading(false);
    }, [user])

    const reloadNewComp = useCallback(async () => {
        setShowTimerLoading(true);

        const { status, } = await getStatusOfRollOver(user);

        if (status === ROLL_OVER_STATUSES.running) {

            setTimeout(() => {
                reloadNewComp();
            }, 5000);

        } else if (status === ROLL_OVER_STATUSES.succeeded) {

            setShowTimerLoading(false);
            await getCompInfo(activeCompetition.compInfo.compId);

            const currentDate = new Date(Date.now());
            currentDate.setUTCMinutes(2);
            currentDate.setUTCSeconds(0);
            currentDate.setUTCHours(12);
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);

            setDateToNextWord(currentDate.toUTCString());
            setShowTimerLoading(false);
        }
    }, [activeCompetition, getCompInfo, user])

    const setDateToNextWordFunc = () => {
        const currentDate = new Date(Date.now());

        currentDate.setUTCMinutes(2);
        currentDate.setUTCSeconds(0);
        currentDate.setUTCHours(12);

        const actualCurrentDate = new Date(Date.now());
        actualCurrentDate.setUTCMinutes(actualCurrentDate.getUTCMinutes() - 15);

        if (actualCurrentDate.getTime() > currentDate.getTime()) {
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        setDateToNextWord(currentDate.toUTCString());
    }

    const changeActiveCompetition = async (compId) => {
        if (activeCompetition && activeCompetition.compInfo.compId === compId) return;

        await getCompInfo(compId);
    }

    const handleRunJoyRide = () => {
        setJoyrideShouldRun(true);
    }

    const getUsersCompetitions = useCallback(async () => {
        const { items, error, meta } = await getCompetitionsForUser(user);

        if (error) {
            handleErrorModalShow(error);
            return;
        }

        if (meta.firstLogin)
            setJoyrideShouldRun(true);

        const { archivedComps, activeComps } = getActiveAndArchivedComps(items);

        setArchivedCompetitions(archivedComps);
        setCompetitions(activeComps);

        if (activeComps && activeComps.length === 1) {
            changeActiveCompetition(activeComps[0].compId);
        }

    }, [getActiveAndArchivedComps, user])

    const setStateFromURL = useCallback(() => {
        if (window.location.href.includes(URL_HELPER.URL_COMPETITION_PARAM)) {
            const competition = window.location.href.split(URL_HELPER.URL_COMPETITION_PARAM)[1];

            getCompInfo(competition);
        }
        else {
        }
        //TODO else 
    }, [getCompInfo])

    useEffect(() => {
        setStateFromURL();

        getUsersCompetitions();
        setDateToNextWordFunc();

        return () => {
            signUserOut();
        }
    }, [setStateFromURL, getUsersCompetitions, signUserOut]);

    return (
        <div className="Main">

            <header className="Container">

                <div className="input-container">
                    <img alt="Main logo for the daily comp" onClick={() => { window.location.href = "/" }} className="logo" src={theDailyCompLogo}></img>

                    <div className="view-competitions-button">
                        <CompDropdown
                            competitions={competitions}
                            setActiveCompetition={setActiveCompetition}
                            title={"Competitions"}
                            changeActiveCompetition={changeActiveCompetition}
                        ></CompDropdown>
                    </div>

                    {
                        (archivedCompetitions.length > 0) ?
                            <CompDropdown
                                competitions={archivedCompetitions}
                                setActiveCompetition={setActiveCompetition}
                                title={"Past Competitions"}
                                changeActiveCompetition={changeActiveCompetition}
                            ></CompDropdown> : null
                    }



                    <Button className="create-competitions-button" style={{ "fontSize": "calc(3px + 2vmin)", color: "rgb(25, 133, 161)" }} variant="dark" onClick={handleShow}> Create new competition</Button>

                    {canUserInviteComp ? <Button style={{ "fontSize": "calc(3px + 2vmin)", color: "rgb(25, 133, 161)" }} variant="dark" onClick={handleInviteModalShow}> Add users here</Button> : null}
                    {canUserInviteComp ? <Button style={{ "fontSize": "calc(3px + 2vmin)", color: "rgb(25, 133, 161)" }} variant="dark" onClick={handleArchiveComp}> Delete</Button> : null}

                    <Button variant="dark" onClick={signUserOut} style={{ alignContent: "center", color: "rgb(25, 133, 161)" }} >
                        <GoSignOut className="sign-out-button" tabIndex="0" size={"calc(8px + 2vmin)"} ></GoSignOut>
                    </Button>

                    <GoQuestion size={"calc(8px + 2vmin)"}
                        onClick={handleRunJoyRide}
                    ></GoQuestion>

                </div>
                <div className="game-area-container">
                    <div className="game-play-container">
                        {
                            activeCompetition !== null ?
                                <CompInfo
                                    activeCompetition={activeCompetition}
                                    reloadNewComp={reloadNewComp}
                                    guesses={guesses}
                                    remainingGuesses={remainingGuesses}>
                                </CompInfo>
                                : null
                        }
                        {activeCompetition && activeCompetition.compInfo.results ?
                            <Results activeCompetition={activeCompetition}></Results> :
                            <Game
                                handleCorrectGuess={reloadCompetitionScoreboard}
                                user={user}
                                activeCompetition={activeCompetition}
                                showErrorModal={handleErrorModalShow}
                                dateToNextWord={dateToNextWord}
                                reloadNewComp={reloadNewComp}
                                showTimerLoading={showTimerLoading}
                                setShowScoreBoard={setShowScoreBoard}
                                remainingGuesses={remainingGuesses}
                                setRemainingGuesses={setRemainingGuesses}
                                guesses={guesses}
                                setGuesses={setGuesses}
                            ></Game>
                        }
                    </div>

                    {
                        showScoreBoard ?
                            <div className="game-scoreboard-container">
                                <div className="game-scoreboard-header-container">
                                    <p className="text">Competition Scoreboard</p>
                                </div>

                                <Scoreboard
                                    isLoading={activeCompetitionLoading}
                                    activeCompetition={activeCompetition}
                                ></Scoreboard>
                            </div> : null
                    }
                </div>

            </header >
            <CreateCompModel
                user={user}
                handleClose={handleClose}
                show={isCreateModelOpen}
                showErrorModal={handleErrorModalShow}
            ></CreateCompModel>

            <InviteUserModal
                user={user}
                handleClose={handleInviteModalClose}
                show={isInviteModalOpen}
                activeCompetition={activeCompetition}
                showErrorModal={handleErrorModalShow}
            ></InviteUserModal>

            <ArchiveCompModal
                user={user}
                handleClose={handleDeleteModalClose}
                show={isDeleteModalOpen}
                activeCompetition={activeCompetition}
                showErrorModal={handleErrorModalShow}
            >
            </ArchiveCompModal>

            <ErrorModal
                handleClose={handleErrorModalClose}
                show={isErrorModalOpen}
                message={errorModelMessage}
            ></ErrorModal>


            <Joyride
                steps={joyRideSteps}
                scrollToFirstStep
                showProgress
                showSkipButton={false}
                hideBackButton
                hideCloseButton
                run={joyrideShouldRun}
                continuous
                disableCloseOnEsc={true}
                disableOverlayClose={true}
            ></Joyride>
        </div >
    )
}

export default Main;