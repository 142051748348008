import React from "react";
import "./Spinner.css";

import LoadingLogo from "../assets/pencil_basic.svg"

const Spinner = () => {

    return (
        <img alt="Loading create competition" className="spin" src={LoadingLogo} />
    )
}



export default Spinner;