import React from "react";
import { Modal, Button } from "react-bootstrap";
import Calendar from "react-calendar";
import {
  URL_HELPER,
  COMPETITION_SUPPORTED_WORD_LENGTHS,
  CREATE_COMP_STATES,
  CREATE_COMP_STATES_ARRAY,
  COMPETITION_THEME_SCRABBLE,
  COMPETITION_THEME_POKEMON,
  COMPETITION_THEME_COUNTRIES
} from "../utils/constants";
import { createCompetition } from "../https_handler/apiHandler";
import Spinner from "./Spinner";

import { DateTime } from "luxon";

import { BsInfoCircle } from "react-icons/bs";

import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CreateCompModel.css"

const CreateCompModel = ({ show, handleClose, user, showErrorModal }) => {
  const [isLoading, setLoading] = React.useState(false);

  const [inputTitle, setTitle] = React.useState("");
  const [inputMaxGuesses, setGuesses] = React.useState(5);
  const [inputWordLengths, setInputWordLengths] = React.useState(["5"]);

  const [endDateValue, endDateValueChange] = React.useState();
  const [startDateValue, startDateValueChange] = React.useState();
  const [compType, changeCompType] = React.useState();
  const [compDifficulty, setCompDifficulty] = React.useState("medium");
  const [compDifficultyText, setCompDifficultyText] = React.useState();
  const [showCompDifficultyModal, setShowCompDifficultyModal] = React.useState(false);
  const [modalState, setModalState] = React.useState(0);

  const handleCreateComp = async () => {

    if (isLoading) return;
    setLoading(true);

    const { jumbled, restrictGuesses } = getCompDifficulty();
    const response = await createCompetition(user, inputTitle, inputMaxGuesses, inputWordLengths.join(","), formatDateString(endDateValue), formatDateString(startDateValue), compType, { jumbled, restrictGuesses });

    if (response.error) {
      showErrorModal(response.error);
      setLoading(false);
      return;
    }

    const baseUrl = window.location.href.split("/")[0];
    window.location.href = `${baseUrl}/${URL_HELPER.URL_COMPETITION_PARAM}${response.compId}`;

    setLoading(false);
    handleClose();
  }

  const getCompDifficulty = () => {
    const jumbled = compDifficulty === "easy" ? true : false;

    const restrictGuesses = compDifficulty === "hard" ? true : false;

    return { jumbled, restrictGuesses }
  }

  const formatDateString = (date) => {
    if (!date) return;

    return DateTime.fromISO(date.toISOString()).toISO();
  }

  const handleCalenderChange = (values) => {
    formatDateString(values[0])
    startDateValueChange(values[0]);
    endDateValueChange(values[1]);
  }

  const handleBackButton = () => {
    if (modalState === 0) return hideModal();
    let pagesToJump = 1;

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_DIFFICULTY) pagesToJump = 2;

    setModalState(modalState - pagesToJump);
  }

  const handleNextButton = () => {
    if (validateStageInput() === false) return;

    if (modalState === CREATE_COMP_STATES_ARRAY.length - 1) return handleCreateComp();

    setModalState(modalState + 1);
  }

  const validateStageInput = () => {
    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_TITLE) return validateStateTitle();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_GUESSES) return validateStateGuesses();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_TYPE) return validateStateType();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_DATE_RANGE) return validateStateDateRange();
  }
  const validateStateTitle = () => {
    if (!inputTitle || inputTitle === "") {
      showErrorModal("You are missing something, we need to know what to call your competition!");
      return false;
    }
    return true;
  }

  const validateStateGuesses = () => {
    if (!inputMaxGuesses || parseInt(inputMaxGuesses) <= 0) {
      showErrorModal("Missing required input, how many attempts should users be restricted to per word?");
      return false;
    }
  }
  const validateStateDateRange = () => {
    if (!endDateValue) {
      showErrorModal("Missing required input, you need to specify the dates in which the competition should start and finish");
      return false;
    }
    return true;
  }

  const validateStateType = () => {
    if (!compType) {
      showErrorModal("Missing required input, what kind of competition is this?");
      return false;
    }
    return true;
  }

  const hideModal = () => {
    setModalState(0);
    setTitle("");
    setGuesses(5);
    setInputWordLengths(["5"]);
    endDateValueChange();
    startDateValueChange();
    changeCompType();
    setCompDifficulty("medium");

    if (!isLoading)
      handleClose();
  }

  const handleChangeCompType = (event) => {
    changeCompType(event.target.value)
  }

  const handleChangeCompDifficulty = (event) => {
    setCompDifficulty(event.target.value)
  }


  const renderCompWordLengths = () => {
    return <div className="input-comp-type-radio-container" >
      {
        COMPETITION_SUPPORTED_WORD_LENGTHS.map(wordLength =>
          <div key={wordLength}>
            <input
              className="input-comp-type-radio"
              type="radio"
              value={wordLength}
              checked={inputWordLengths.includes(wordLength)}
              onClick={handleWordLengthRadioChange}
              onChange={() => { }}
            ></input> {wordLength}
          </div>
        )
      }
    </div>
  }

  const handleWordLengthRadioChange = (event) => {
    const inputWordLength = event.target.value;

    if (inputWordLengths.includes(inputWordLength)) {
      if (inputWordLengths.length === 1) return;

      const wordLengths = inputWordLengths.filter(wordLength => wordLength !== inputWordLength)
      setInputWordLengths(wordLengths);
      return;
    }

    const wordLengths = [...inputWordLengths, inputWordLength];

    if (wordLengths.length > 2) wordLengths.shift();

    setInputWordLengths(wordLengths);
  }

  const getCompDifficultyText = () => {
    if (compDifficultyText === "Easy") return "A jumbled version of the word will be presented to the user as a hint.";
    if (compDifficultyText === "Medium") return "No hints and all valid words can be used as guesses.";
    if (compDifficultyText === "Hard") return "No hints and only words relating to the selected theme can be used as guesses e.g. If the countries theme is selected users can only attempt guesses using valid country names and not generic words.";
  }

  const showDifficultyModal = (compDifficulty) => {
    setCompDifficultyText(compDifficulty)
    setShowCompDifficultyModal(true);
  }

  const hideDifficultyModal = () => {
    setShowCompDifficultyModal(false);
  }



  const renderStateTitle = () => {
    return <Modal
      size="lg"
      centered
      show={show}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <p className="model-heading" >Create your competition:</p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="single-input-form-container">

            <div className="input-form-text-container">
              <p className="modal-body-text">What is the title of your competition?</p>
            </div>

            <div className="input-form-container">
              <input key={"input_title"} value={inputTitle} onChange={(event => { setTitle(event.target.value) })}></input>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="model-footer" >
        <Button variant="secondary" onClick={handleBackButton}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNextButton}>
          Next
        </Button>
      </Modal.Footer>
    </Modal >
  }

  const renderStateGuesses = () => {
    return <Modal
      size="lg"
      centered
      show={show}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <p className="model-heading" > Competition: {inputTitle}</p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="single-input-form-container">

            <div className="input-form-text-container">
              <p className="modal-body-text">How many times can players try and guess the word correctly?</p>
            </div>

            <div className="input-form-container">
              <input type="number" key={"input_max_guesses"} defaultValue={inputMaxGuesses} onChange={(event => { setGuesses(event.target.value) })}></input>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="model-footer" >
        <Button variant="secondary" onClick={handleBackButton}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNextButton}>
          Next
        </Button>
      </Modal.Footer>
    </Modal >
  }

  const renderStateWordLength = () => {
    if (compType !== COMPETITION_THEME_SCRABBLE) return handleNextButton();

    return <Modal
      size="lg"
      centered
      show={show}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <p className="model-heading" >Competition: {inputTitle}</p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="single-input-form-container">

            <div className="input-form-text-container">
              <p>Length of words:</p>
              <p>You can select up to two different lengths. </p>
            </div>

            <div className="input-form-container"
            >
              {
                renderCompWordLengths()
              }
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="model-footer" >
        <Button variant="secondary" onClick={handleBackButton}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNextButton}>
          Next
        </Button>
      </Modal.Footer>
    </Modal >
  }
  const renderStateType = () => {
    return <Modal
      size="lg"
      centered
      show={show}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <p className="model-heading" >Competition: {inputTitle}</p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="single-input-form-container">

            <div className="input-form-text-container-type">
              <p>Competition type:</p>
              <p>Themed: These types will include all words about a certain theme.</p>
            </div>
            <div className="input-form-container-type">
              <div
                onChange={handleChangeCompType}
                className="input-comp-type-radio-container-type"
              >
                <div>
                  <input
                    className="input-comp-type-radio"
                    type="radio"
                    value={COMPETITION_THEME_SCRABBLE}
                    name="compType"
                    key="input-comp-type-theme_scrabble"
                    defaultChecked={compType === COMPETITION_THEME_SCRABBLE ? true : false}
                  /> Scrabble (Themed)
                </div>

                <div>
                  <input
                    className="input-comp-type-radio"
                    type="radio"
                    value={COMPETITION_THEME_POKEMON}
                    name="compType"
                    key="input-comp-type-theme_pokemon"
                    defaultChecked={compType === COMPETITION_THEME_POKEMON ? true : false}
                  /> Pokemon (Themed)
                </div>

                <div>
                  <input
                    className="input-comp-type-radio"
                    type="radio"
                    value={COMPETITION_THEME_COUNTRIES}
                    name="compType"
                    key="input-comp-type-theme_countries"
                    defaultChecked={compType === COMPETITION_THEME_COUNTRIES ? true : false}
                  /> Countries (Themed)
                </div>
              </div>
            </div>

          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="model-footer" >
        <Button variant="secondary" onClick={handleBackButton}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNextButton}>
          Next
        </Button>
      </Modal.Footer>
    </Modal >
  }

  const renderStateDateRange = () => {
    return <Modal
      size="lg"
      centered
      show={show}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <p className="model-heading" >Competition: {inputTitle}</p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="single-input-form-container-calender">

            {isLoading ? <Spinner></Spinner> : null}
            <div className="input-form-text-container-calender">
              <p>Select a range of dates in which this competition will run for (selecting only one in the calendar will result the competition only running for one day).</p>
            </div>

            <div className="input-form-container-calender">
              <Calendar
                onChange={handleCalenderChange}
                returnValue={"range"}
                selectRange={true}
                value={[startDateValue, endDateValue]}
                minDate={new Date(Date.now())}
              />
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="model-footer" >
        <Button variant="secondary" onClick={handleBackButton}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNextButton}>
          Next
        </Button>
      </Modal.Footer>
    </Modal >
  }

  const renderStateDifficulty = () => {
    return <Modal
      size="lg"
      centered
      show={show}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <p className="model-heading" >Competition: {inputTitle}</p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="single-input-form-container-calender">

            <div className="input-form-text-container-calender">
              <p>Set competition difficulty</p>
            </div>
            <div className="input-form-container-type">

              <div
                onChange={handleChangeCompDifficulty}
                className="input-comp-type-radio-container"
              >
                <div >
                  <input
                    className="input-comp-type-radio"
                    type="radio"
                    key="input-comp-difficulty-easy"
                    defaultChecked={compDifficulty === "easy" ? true : false}
                    value="easy"
                    name="compDifficulty"
                  /> Easy

                  <BsInfoCircle size={"calc(4px + 1.5vmin)"}
                    style={{ marginLeft: "2px" }}
                    onClick={() => showDifficultyModal("Easy")}
                  ></BsInfoCircle>
                </div>

                <div >
                  <input
                    className="input-comp-type-radio"
                    type="radio"
                    key="input-comp-difficulty-medium"
                    defaultChecked={compDifficulty === "medium" ? true : false}
                    value="medium"
                    name="compDifficulty"
                  /> Medium
                  <BsInfoCircle size={"calc(4px + 1.5vmin)"}
                    style={{ marginLeft: "2px" }}
                    onClick={() => showDifficultyModal("Medium")}
                  ></BsInfoCircle>
                </div>

                <div >
                  <input
                    className="input-comp-type-radio"
                    type="radio"
                    key="input-comp-difficulty-hard"
                    defaultChecked={compDifficulty === "hard" ? true : false}
                    value="hard"
                    name="compDifficulty"
                  /> Hard
                  <BsInfoCircle size={"calc(4px + 1.5vmin)"}
                    style={{ marginLeft: "2px" }}
                    onClick={() => showDifficultyModal("Hard")}
                  ></BsInfoCircle>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="model-footer" >
        <Button variant="secondary" onClick={handleBackButton}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNextButton}>
          Next
        </Button>
      </Modal.Footer>
    </Modal >
  }

  const renderModal = () => {
    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_TITLE) return renderStateTitle();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_WORD_LENGTH) return renderStateWordLength();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_DIFFICULTY) return renderStateDifficulty();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_TYPE) return renderStateType();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_GUESSES) return renderStateGuesses();

    if (CREATE_COMP_STATES_ARRAY[modalState] === CREATE_COMP_STATES.STATE_DATE_RANGE) return renderStateDateRange();
  }

  return (
    <>
      {
        renderModal()
      }

      <Modal
        size="lg"
        centered
        show={showCompDifficultyModal}
        onHide={hideDifficultyModal}
      >
        <Modal.Header closeButton>
          <p className="model-heading" >Difficulty: {compDifficultyText}</p>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>{`${getCompDifficultyText()}`}</p>
          </>
        </Modal.Body>
        <Modal.Footer className="model-footer" >
          <Button variant="primary" onClick={hideDifficultyModal}>
            I understand
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}

export default CreateCompModel;