import React from "react";
import "./Spinner.css";

import LoadingLogo from "../assets/pencil_basic.svg"

const InlineSpinner = () => {

    return (
        <img alt="loading new word" className="spin-inline" src={LoadingLogo} />
    )
}



export default InlineSpinner;