import React from "react";
import "./Scoreboard.css";

import PencilLogoGold from "../assets/Pencil_gold.svg";
import PencilLogoSilver from "../assets/Pencil_silver.svg";
import PencilLogoBronze from "../assets/Pencil_bronze.svg";

const Scoreboard = ({ activeCompetition }) => {

    const renderUsers = () => {

        if (activeCompetition && ((activeCompetition.topUsers) || (activeCompetition.compInfo && activeCompetition.compInfo.results && activeCompetition.compInfo.results.topUsers))) {
            const topUsers = (activeCompetition.compInfo && activeCompetition.compInfo.results && activeCompetition.compInfo.results.topUsers) ? activeCompetition.compInfo.results.topUsers : activeCompetition.topUsers.slice(0, 20);
            return topUsers.map((user, index) => {
                return <div key={`${user.username}`} className="scoreboard-entry">

                    <div className="scoreboard-entry-position">
                        {index === 0 ?
                            <img alt="The golden pencil for first place" className="placement-logo" src={PencilLogoGold}></img>
                            : index === 1 ?
                                <img alt="The silver pencil for second place" className="placement-logo" src={PencilLogoSilver}></img> :
                                index === 2 ?
                                    <img alt="The bronze pencil for third place" className="placement-logo" src={PencilLogoBronze}></img> :
                                    <p className="scoreboard-text">
                                        #{index + 1}
                                    </p>
                        }
                    </div>

                    <div className="scoreboard-entry-user">
                        <p className="scoreboard-text">
                            {user.username}: {user.score}
                        </p>
                    </div>

                </div >
            }
            )
        }
    }

    return (
        <div className="scoreboard-entries-container">
            {renderUsers()}
        </div>
    )
}



export default Scoreboard;