import "./CompDropdown.css"
import React from "react";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { URL_HELPER } from "../utils/constants";

const CompDropdown = ({ competitions, title, changeActiveCompetition }) => {
    const [hoverText, setHoverText] = React.useState();
    const [isHovering, setIsHovering] = React.useState(false);
    const [activeHoveringComp, setActiveHoveringComp] = React.useState({});

    const handleCompClick = (competition) => {
        window.history.replaceState(null, `Competition ${competition.compId}`, getHref(competition))
        changeActiveCompetition(competition.compId);
    }

    const renderCompItems = () => {
        if (!competitions || competitions.length === 0) return null;
        return (competitions.map((competition, index) => {
            return <div className="dropdown-item-container" key={`comp_${index}`}>
                <Dropdown.Item
                    onClick={() => { handleCompClick(competition) }}
                >{competition.title}</Dropdown.Item>
                <div
                    onMouseEnter={() => { handleMouseEnter(competition) }}
                    onMouseLeave={handleMouseLeave}
                    className="status-circle"
                    style={{ backgroundColor: getCompetitionStatusColour(competition) }}
                >
                    {(isHovering && competition.compId === activeHoveringComp.compId) ? <div className="hover-text">{hoverText}</div> : null}
                </div>
            </div >
        }))
    }

    const handleMouseEnter = (competition) => {
        setHoverText(getHoverOverText(competition));
        setIsHovering(true);
        setActiveHoveringComp(competition);
    }

    const handleMouseLeave = () => {
        setIsHovering(false);
        setHoverText();
        setActiveHoveringComp({})
    }
    const getHoverOverText = (competition) => {
        if (competition.finished) return "Finished";

        if (competition.started === false) return "Starting soon";

        return "Running";
    }


    const getCompetitionStatusColour = (competition) => {
        if (competition.finished) return "red";

        if (competition.started === false) return "yellow";

        return "green";
    }

    const getHref = (competition) => {
        const baseUrl = window.location.href.split("/")[0];

        return `${baseUrl}/${URL_HELPER.URL_COMPETITION_PARAM}${competition.compId}`;
    }

    return (
        <Dropdown>
            <Dropdown.Toggle style={{ "fontSize": "calc(3px + 2vmin)", color: "rgb(25, 133, 161)" }} variant="dark" id="dropdown-basic">
                {title}
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
                {renderCompItems()}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default CompDropdown;