import "./InviteUserModal.css"
import React from "react";

import { inviteUserToComp } from "../https_handler/apiHandler";

import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const InviteUserModal = ({ show, handleClose, user, activeCompetition, showErrorModal }) => {
    const [isLoading, setLoading] = React.useState(false);
    const [inputEmail, setInputEmail] = React.useState("");
    const [statusColour, setStatusColour] = React.useState("rgba(255, 0, 0, .4)");

    const handleInviteUser = async () => {
        if (isLoading) return;

        if (!isValidEmail(inputEmail)) return;

        setLoading(true);
        const { error } = await inviteUserToComp(user, activeCompetition.compInfo.compId, inputEmail);

        if (error)
            showErrorModal(error);

        setInputEmail("");

        setLoading(false);
    }

    const hideModal = () => {
        if (!isLoading)
            handleClose();
    }

    const getActiveCompTitle = () => {
        if (activeCompetition)
            return activeCompetition.compInfo.title;
    }

    const validateAndSetEmail = (email) => {
        if (isValidEmail(email)) {
            setStatusColour("rgba(0, 255, 0, .4)")
        } else
            setStatusColour("rgba(255, 0, 0, .4)");

        setInputEmail(email);
    }

    const isValidEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email)
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <p className="model-heading" > Invite user to competition: {getActiveCompTitle()}</p>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="single-input-form-container">

                        <div className="input-form-text-container">
                            <p>Users email </p>
                        </div>

                        <div className="input-form-container">
                            <input
                                style={{ backgroundColor: statusColour }}
                                value={inputEmail}
                                onChange={(event => { validateAndSetEmail(event.target.value) })}>
                            </input>
                        </div>
                    </div>


                </>
            </Modal.Body>
            <Modal.Footer className="model-footer" >
                <Button variant="secondary" onClick={hideModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleInviteUser}>
                    Invite
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default InviteUserModal;