import React from "react";

import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ErrorModal = ({ show, message, handleClose }) => {

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <p className="model-heading" >Error:</p>
            </Modal.Header>
            <Modal.Body>
                <>
                    <p>{message}</p>
                </>
            </Modal.Body>
            <Modal.Footer className="model-footer" >

                <Button variant="primary" onClick={handleClose}>
                    Ok
                </Button>

            </Modal.Footer>
        </Modal >
    )
}

export default ErrorModal;