import "./Results.css"
import PencilLogoGold from "../assets/Pencil_gold.svg";
import PencilLogoSilver from "../assets/Pencil_silver.svg";
import PencilLogoBronze from "../assets/Pencil_bronze.svg";

const Results = ({ activeCompetition }) => {
    const getPlacementText = (placement, totalUsers) => {
        if (placement + 1 === totalUsers) return "... Theres all ways next time, maybe rethink your strategy.";

        if (placement === 0) return "Congrats!! You won!";
        if (placement <= 2) return "You got a placement, so close to the golden pencil!";
        if (placement > 99) return "Nice work, keep fighting for a podium finish next time.";
        return "... Theres all ways next time, maybe rethink your strategy.";
    }

    return (
        <div className="results-container" >
            <p className="text-podium">This competition has come to an end, and the winners are:</p>
            <div className="results-podium" >
                <div className="results-podium-placement-gold" >
                    <p className="text-podium">{activeCompetition.compInfo.results.topUsers.length >= 1 ? activeCompetition.compInfo.results.topUsers[0].username : "Unknown winner"}</p>
                    <img alt="The golden pencil for first place" className="podium-logo-gold" src={PencilLogoGold}></img>
                </div >

                <div className="results-podium-lower-tier">
                    <div className="results-podium-placement-silver" >
                        <p className="text-podium">{activeCompetition.compInfo.results.topUsers.length >= 2 ? activeCompetition.compInfo.results.topUsers[1].username : "Unknown second"}</p>
                        <img alt="The silver pencil for second place" className="podium-logo-silver" src={PencilLogoSilver}></img>
                    </div >

                    <div className="results-podium-placement-bronze" >
                        <p className="text-podium">{activeCompetition.compInfo.results.topUsers.length >= 3 ? activeCompetition.compInfo.results.topUsers[2].username : "Unknown third"}</p>
                        <img alt="The bronze pencil for third place" className="podium-logo-bronze" src={PencilLogoBronze}></img>
                    </div >
                </div>
            </div >


            <p className="text-podium">View your results below:</p>
            <p className="text-podium">Your final score: {activeCompetition.compInfo.results.usersPosition.score}</p>
            <p className="text-podium">You placed: {activeCompetition.compInfo.results.usersPosition.place + 1} {getPlacementText(activeCompetition.compInfo.results.usersPosition.place, activeCompetition.compInfo.results.totalUsers)}</p>
            <p className="text-podium">Total players: {activeCompetition.compInfo.results.totalUsers}</p>
        </div >
    );
}


export default Results;
