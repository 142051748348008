import React from "react";
import { Modal } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

const WordDefinitionModal = ({ show, handleClose, guess, wordDefinitions }) => {

    const hideModal = () => {
        handleClose();
    }

    const renderDefinitions = () => {
        if (wordDefinitions[guess] && wordDefinitions[guess].length > 0)
            return wordDefinitions[guess].map(definition =>
                <p className="text-word-definition">- {definition.definition}</p>
            )
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <p className="model-heading" > Word definition: {guess}</p>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    {
                        renderDefinitions()
                    }
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default WordDefinitionModal;