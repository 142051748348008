import "./Game.css"
import React, { useRef } from 'react';

import { ALPHABET, COMPETITION_THEME_COUNTRIES, COMPETITION_THEME_POKEMON, COMPETITION_THEME_SCRABBLE, KEYBOARD_INPUT_KEYS, ON_SCREEN_KEYBOARD_LAYOUT } from "../utils/constants";
import globalDict from "../utils/globalDict.json";
import globalDictPokemon from "../utils/globalDict_pokemon.json";
import globalDictCountries from "../utils/globalDict_countries.json";

import { GoQuestion, GoKeyboard } from "react-icons/go";

import { guessWordCompetition, getWordDefinitions } from "../https_handler/apiHandler";

import CountDownTimer from "./CountDownTimer";

import WordDefinitionModal from "./WordDefinitionModal";
import "bootstrap/dist/css/bootstrap.min.css";

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import GuessButton from "../assets/guess_button.svg";

import { isMobile } from 'react-device-detect';

const Game = ({ user, activeCompetition, showErrorModal, handleCorrectGuess, dateToNextWord, reloadNewComp, showTimerLoading, setShowScoreBoard, setRemainingGuesses, remainingGuesses, guesses, setGuesses }) => {
    const [word, setWord] = React.useState("");
    const [isSendingGuess, setIsSendingGuess] = React.useState(false);
    const [showWordDefinitionModal, setShowWordDefinitionModal] = React.useState(false);
    const [wordDefinitions, setWordDefinitions] = React.useState(false);
    const [letterColor, setLetterColor] = React.useState();
    const [correctGuess, setCorrectGuess] = React.useState();
    const [showKeyboard, setShowKeyboard] = React.useState(false);
    const [loadingDefinition, setLoadingDefinition] = React.useState(false);
    const [finishedAnimation, setFinishedAnimation] = React.useState(true);

    const inputContainer = useRef(null);

    const handleKeyInput = (key) => {
        if (word.length < activeCompetition.compInfo.wordLength) {
            setLetterColor("black");
            setWord(`${word}${key}`);
        }
    }

    const handleDeleteKey = () => {
        setWord(word.slice(0, word.length - 1))
    }

    const handleEnterKey = () => {
        if (word.length === activeCompetition.compInfo.wordLength) {

            if (isValidWord(word)) {

                if (guesses.length >= activeCompetition.compInfo.maxGuesses) return;

                if (!activeCompetition) showErrorModal("Select a competition first");

                if (activeCompetition) makeGuessComp();

            } else setLetterColor("red");
        } else {
            setLetterColor("red");
        }
    }

    const shouldRenderTimerToNextWord = () => {
        if (!activeCompetition) return false;

        if (getGuessCount() === activeCompetition.compInfo.maxGuesses) return true;

        if (activeCompetition && activeCompetition.user && activeCompetition.user.currentGuesses.length > 0)
            if (activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].isCorrect) return true;

        return false;
    }

    const makeGuessComp = () => {
        if (activeCompetition.compInfo.finished === true) return showErrorModal("Sorry this competition is no longer running.");
        if (activeCompetition.compInfo.started === false) return showErrorModal("Sorry this competition has not started yet please wait until competitions starts.");

        if (isSendingGuess) return;
        setIsSendingGuess(true);
        setFinishedAnimation(false);

        guessWordCompetition(user, word, activeCompetition.compInfo.compId).then((result) => {

            const { error, isCorrect } = result;
            if (error) {
                showErrorModal(error);
                return;
            }


            setIsSendingGuess(false);
            setGuesses([...guesses, { word: word, result, compId: activeCompetition.compInfo.compId }]);
            setRemainingGuesses(remainingGuesses - 1)
            setWord("");

            if (isCorrect) {
                setTimeout(() => {
                    handleCorrectGuess().then(() => {
                        setCorrectGuess(word)
                        setGuesses([]);
                        setFinishedAnimation(true);
                    });
                }, (activeCompetition.compInfo.wordLength * 1000));
            }

        });

    }

    const handleKeyCapture = (event) => {
        if (guesses.length === activeCompetition.compInfo.maxGuesses) return;

        const key = typeof event === "string" ? event : event.key.toLowerCase();

        if (key === KEYBOARD_INPUT_KEYS.BACKSPACE || key === KEYBOARD_INPUT_KEYS.SCREEN_KEYBOARD_BACKSPACE) {
            handleDeleteKey();
        }

        if (key === KEYBOARD_INPUT_KEYS.ENTER || key === KEYBOARD_INPUT_KEYS.SCREEN_KEYBOARD_ENTER) {
            if (word.length === activeCompetition.compInfo.wordLength)
                handleEnterKey();
            else handleInputContainerClick();
        }

        if (ALPHABET.includes(key))
            handleKeyInput(key)

    }

    const renderGuesses = () => {
        if (guesses.length > 0) {
            if (guesses[0].compId !== activeCompetition.compInfo.compId)
                setGuesses([])
        }

        let combinedGuess = guesses;
        if (activeCompetition && activeCompetition.user)
            combinedGuess = [...activeCompetition.user.currentGuesses.map(guess => ({ word: guess.guess, result: guess })), ...guesses];

        return (combinedGuess.map((guess, index) => {
            return <div key={`outer_container_${index}`} className="guess-word-container-full" >
                <div className="guess-word-container"
                    key={`inner_container_${index}`}
                >
                    {
                        renderGuess(guess, index, index === combinedGuess.length - 1)
                    }
                </div>
                {
                    guess.result.isCorrect && finishedAnimation && activeCompetition.compInfo.compType === COMPETITION_THEME_SCRABBLE ?
                        <div
                            className="guess-word-container-info"
                            key={"correct_guess_info_container"}
                        >
                            <GoQuestion size={"calc(8px + 2vmin)"}
                                onMouseEnter={handleMouseEnterInfoModal}
                                onClick={handleMouseEnterInfoModal}
                                key={"correct_guess_info"}
                            ></GoQuestion>
                        </div>
                        : null

                }
            </div >
        }))
    }

    const shouldRenderGuessKey = () => {
        if (guesses.length > 0 || (activeCompetition && activeCompetition && activeCompetition.user.currentGuesses.length > 0)) return true;

        return false;
    }

    const renderGuess = (guess, index, isLatestGuess) => {
        const letters = guess.word.split("");

        const isFinalGuess = index === activeCompetition.compInfo.maxGuesses - 1;
        const isCurrentInput = guesses.length > 0;

        return letters.map((guessLetter, letterIndex) => {
            if (isCurrentInput) {
                if (isFinalGuess)
                    return <SingleWordInputSpinning
                        key={`guess_${index}_${letterIndex}`}
                        keyName={`guess_${index}_${letterIndex}`}
                        input={guessLetter}
                        letterColor={getGuessLetterColour(guess, letterIndex)}
                        marginRight={"10%"}
                        index={letterIndex}
                    > {guessLetter}</SingleWordInputSpinning>

                if (isLatestGuess)
                    return <SingleWordInputFading
                        key={`guess_${index}_${letterIndex}`}
                        keyName={`guess_${index}_${letterIndex}`}
                        input={guessLetter}
                        letterColor={getGuessLetterColour(guess, letterIndex)}
                        marginRight={"10%"}
                        index={letterIndex}
                    > {guessLetter}</SingleWordInputFading>
            }

            return <SingleWordInput
                key={`guess_${index}_${letterIndex}`}
                keyName={`guess_${index}_${letterIndex}`}
                input={guessLetter}
                letterColor={getGuessLetterColour(guess, letterIndex)}
                marginRight={"10%"}
            > {guessLetter}</SingleWordInput>
        })
    }

    const renderLetterInputs = () => {
        if (activeCompetition && activeCompetition.user)
            return [...new Array(activeCompetition.compInfo.wordLength)].map((value, index) => {
                if (word.length !== index)
                    return <SingleWordInput
                        key={`letter_${index}`}
                        keyName={`current_word_letter_${index}`}
                        id={index}
                        input={word.split("")[index]}
                        letterColor={letterColor}
                    ></SingleWordInput>

                else return <div key={`blinking_cursor`} className="letter-input-blinking">
                    <p key={`blinking_cursor_value`}  >_</p>
                </div >
            })
    }

    const getGuessLetterColour = (guess, letterIndex) => {
        if (guess.result.occurrences[letterIndex] === 2) return "green";

        if (guess.result.occurrences[letterIndex] === 1) return "yellow"

        return "red";
    }

    const getGuessCount = () => {
        let guessCount = guesses.length;

        if (activeCompetition && activeCompetition.user)
            guessCount += activeCompetition.user.guessCountForIteration;

        if (guessCount < 0) guessCount *= -1;

        return guessCount;
    }

    const handleMouseEnterInfoModal = () => {
        if (loadingDefinition) return;

        setLoadingDefinition(true);

        let guess = correctGuess;
        const currentGuess = getCorrectGuessWord();

        if (!correctGuess || guess !== currentGuess) {
            guess = currentGuess;
            setCorrectGuess(guess)
        }

        if (wordDefinitions[guess]) {
            setLoadingDefinition(false);
            setShowWordDefinitionModal(true);
        }
        else {
            getWordDefinitions(guess, user).then(response => {
                setLoadingDefinition(false);
                setWordDefinitions({ ...wordDefinitions, [guess]: response });
                setShowWordDefinitionModal(true);
            });
        }
    }

    const handleMouseLeaveInfoModal = async () => {
        setShowWordDefinitionModal(false);
    }

    const getCorrectGuessWord = () => {
        if (activeCompetition && activeCompetition.user && activeCompetition.user.currentGuesses.length > 0)
            if (activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].isCorrect)
                return activeCompetition.user.currentGuesses[activeCompetition.user.currentGuesses.length - 1].guess;
    }

    const handleInputContainerClick = (force) => {
        if (isMobile || force === true) {
            if (isMobile)
                setShowScoreBoard(showKeyboard)
            setShowKeyboard(!showKeyboard);
        }
    }

    const isValidWord = (word) => {
        const { compType, difficulty } = activeCompetition.compInfo;

        if (!difficulty.restrictGuesses || compType === COMPETITION_THEME_SCRABBLE) if (globalDict[word]) return true;

        if (compType === COMPETITION_THEME_COUNTRIES) {
            if (globalDictCountries.words.includes(word)) return true;
        }
        else if (compType === COMPETITION_THEME_POKEMON) if (globalDictPokemon.words.includes(word)) {
            return true
        };
    }

    return (
        <div className="game-container">
            <div className="game-header">
                <GoKeyboard className="show-onscreen-keyboard-button" size={"calc(8px + 2.5vmin)"}
                    onClick={() => { handleInputContainerClick(true) }}
                ></GoKeyboard>
                <p className="text-hint">{activeCompetition && activeCompetition.compInfo.hint ? `Hint: "${activeCompetition.compInfo.hint}"` : null} </p>
            </div>

            <div className="letter-area-container" >
                {shouldRenderTimerToNextWord() ?
                    <div className="letter-input-container" key="countdownTimer" tabIndex="0" onKeyUpCapture={handleKeyCapture} >
                        <CountDownTimer
                            startDate={dateToNextWord}
                            text={"New word in:"}
                            reloadNewComp={reloadNewComp}
                            showTimerLoading={showTimerLoading}
                        ></CountDownTimer>
                    </div>

                    :
                    <div className="letter-input-container" key="inputContainer" tabIndex="0" onKeyUpCapture={handleKeyCapture} ref={inputContainer} onClick={handleInputContainerClick} >
                        {
                            renderLetterInputs()
                        }
                    </div>
                }
                <div className="letter-button-container"  ><img alt="Button to attempt guess" onClick={handleEnterKey} onKeyUpCapture={handleKeyCapture} className="guess-button" tabIndex="0" src={GuessButton}></img></div>

            </div>


            <div className="guess-container" key="guessContainer" id="guess-container" >
                {renderGuesses()}
            </div>

            {
                shouldRenderGuessKey() ?
                    <div className="guess-word-container" key="guessKeyContainer" >
                        <div className="status-circle-key-container">
                            <div
                                className="status-circle-key"
                                style={{ backgroundColor: "red" }}
                            ></div>
                            <p className="status-circle-key-text">Wrong letter, keep trying</p>
                        </div>

                        <div className="status-circle-key-container">
                            <div
                                className="status-circle-key"
                                style={{ backgroundColor: "yellow" }}
                            ></div>
                            <p className="status-circle-key-text">Correct letter, wrong location</p>
                        </div>


                        <div className="status-circle-key-container">
                            <div
                                className="status-circle-key"
                                style={{ backgroundColor: "green" }}
                            ></div>
                            <p className="status-circle-key-text">Correct letter, right location</p>
                        </div>
                    </div>
                    : null
            }


            {showKeyboard ?
                <div className="on-screen-keyboard">
                    <Keyboard
                        onKeyPress={handleKeyCapture}
                        layout={ON_SCREEN_KEYBOARD_LAYOUT}
                    ></Keyboard>
                </div> : null
            }

            <WordDefinitionModal
                show={showWordDefinitionModal}
                handleClose={handleMouseLeaveInfoModal}
                guess={correctGuess}
                wordDefinitions={wordDefinitions}
            ></WordDefinitionModal>
        </div >
    )
}

export default Game;

const SingleWordInput = ({ input, letterColor, keyName }) => {
    return (
        <div className="letter-input"
            style={{ color: letterColor }}
            key={`container_${keyName}`}
        >
            <p key={`letter_${keyName}`}>{input}</p>
        </div>
    )
}

const SingleWordInputFading = ({ input, letterColor, keyName, index }) => {
    return (
        <div style={{
            minWidth: "10%",
            opacity: 0,
            fontSize: "calc(10px + 2vmin)",
            animation: `fadeIn 0.1s`,
            animationDelay: `${index / 2}s`,
            animationFillMode: "forwards",
            color: letterColor
        }}
            key={`container_${keyName}`}
        >
            <p key={`letter_${keyName}`}>{input}</p>
        </div>
    )
}

const SingleWordInputSpinning = ({ input, letterColor, keyName, index }) => {
    return (
        <div className="letter-input"
            style={{
                minWidth: "10%",
                fontSize: "calc(10px + 2vmin)",
                animation: `spinLetter ${(index + 5) / 2}s, colourTransform-${letterColor} 200ms ease-in-out ${(index + 5) / 2}s`,
                color: "black",
                animationFillMode: "forwards",
            }}
            key={`container_${keyName}`}
        >
            <p key={`letter_${keyName}`}>{input}</p>
        </div>
    )
}