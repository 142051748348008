
import { GENERIC_ERROR, STATUS_202, STATUS_200, STATUS_400, BASE_URL, HTTP_METHOD_GET, HTTP_METHOD_POST, HTTP_METHOD_DELETE } from "../utils/constants";

export const createCompetition = async (user, title, maxGuesses, dictLengths, endDate, startDate, compType, options) => {
    try {
        if (endDate)
            endDate = new Date(endDate).toISOString();

        const jumbledParam = options.jumbled ? "&jumbled=true" : "";
        const restrictGuessesParam = options.restrictGuesses ? "&restrictGuesses=true" : "";

        const url = `${BASE_URL}/competitions/dictionaryLengths/${dictLengths}?maxGuesses=${maxGuesses}&title=${title}&type=${compType}${jumbledParam}${restrictGuessesParam}`

        const result = await sendRequest(user, url, HTTP_METHOD_POST, { body: { startDate, endDate } });

        const status = result.status;
        if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

        return result.json();;

    } catch (e) {
        return GENERIC_ERROR;
    }
}

export const getCompetitionsForUser = async (user) => {
    const url = `${BASE_URL}/competitions`;

    const result = await sendRequest(user, url, HTTP_METHOD_GET, {})

    const status = result.status;
    if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

    return await result.json();
}


export const getCompetitionForUser = async (user, compId) => {
    const url = `${BASE_URL}/competitions/${compId}`

    const result = await sendRequest(user, url, HTTP_METHOD_GET, {})

    const status = result.status;
    if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

    return await result.json();
}

export const archiveCompetition = async (user, compId) => {
    const url = `${BASE_URL}/competitions/${compId}`

    const result = await sendRequest(user, url, HTTP_METHOD_DELETE, {})

    const status = result.status;
    if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

    return result.json();
}

export const guessWordCompetition = async (user, word, compId) => {
    const url = `${BASE_URL}/guess/${word}/competitions/${compId}`

    const result = await sendRequest(user, url, HTTP_METHOD_POST, {})

    const status = result.status;
    if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

    return result.json();
}

export const inviteUserToComp = async (user, compId, email) => {
    const url = `${BASE_URL}/invites/${email}/competitions/${compId}`

    const result = await sendRequest(user, url, HTTP_METHOD_POST, {})

    const status = result.status;
    if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

    return result.json();
}


export const getStatusOfRollOver = async (user) => {
    const url = `${BASE_URL}/status`;

    const result = await sendRequest(user, url, HTTP_METHOD_GET, {})

    const status = result.status;
    if (status !== STATUS_400 && status !== STATUS_202) return GENERIC_ERROR;

    return result.json();
}


export const iterateDictionaryUsage = async (user) => {
    const url = `${BASE_URL}/usage`

    await sendRequest(user, url, HTTP_METHOD_POST, {})

    return true;
}


export const getWordDefinitions = async (word, user) => {
    try {
        const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
        const result = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const status = result.status;
        const response = await result.json();

        if (status === 404) return [{ definition: response.message }]
        if (status !== STATUS_400 && status !== STATUS_200) return [{ definition: `No definitions found for word '${word}'` }];

        iterateDictionaryUsage(user).then(response => { });

        const { meanings } = response[0];
        let wordDefinitions = [];

        for (const meaning of meanings) {
            wordDefinitions = [...wordDefinitions, ...meaning.definitions];
        };

        return wordDefinitions;
    }
    catch (e) {
        return [{ definition: `No definitions found for word '${word}'` }]
    }
}

const sendRequest = async (user, url, method, options) => {
    const token = user.signInUserSession.idToken.jwtToken;

    const body = method === HTTP_METHOD_POST ? options.body ? JSON.stringify(options.body) : "{}" : undefined;

    return fetch(url, {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body
    });
}